
import { computed, reactive } from "vue";
import validate from "@/utils/validate";
import { visitorService } from "@/service";
import moment from "moment";
import rulesRegulations from "@/views/rules-regulations/index.vue";
import { Toast } from "vant";
import { useRouter } from "vue-router";
import { RouterName } from "@/router";
export default {
  components: {
    rulesRegulations
  },
  setup() {
    const router = useRouter();
    const configInfo = reactive({
      visit_length: "1",
      visit_stores_from: {},
      visit_diy_field: [],
      visit_slas: {
        state: 0
      }
    });
    const getConfig = async () => {
      try {
        const { data } = await visitorService.configInfo();
        Object.assign(configInfo, data.data);
        formData.stores_arr = data.data.visit_stores_from.stores_arr;
        if (data.data.visit_diy_field.length > 0) {
          data.data.visit_diy_field.forEach((item: any) => {
            formData.visit_diy_field.push({
              title: item.title,
              id: item.id,
              required: item.required === "1",
              value: ""
            });
          });
        }
      } catch (error) {}
    };
    getConfig();

    const currentDate = moment();
    const formData = reactive({
      person_lists: [
        {
          key: new Date().getTime(),
          name: "",
          mobile: "",
          email: ""
        }
      ],
      person_reason: "",
      person_date: "",
      visit_stores_id: "",
      visit_stores_name: "",
      visit_user_name: "",
      visit_user_mobile: "",
      visit_user_email: "",
      visit_diy_field: [] as any[],
      checked: false,
      showRules: false,
      showPicker: false,
      pickerConfirm(data: any) {
        formData.visit_stores_name = data.stores_name;
        formData.visit_stores_id = data.stores_id;
        formData.showPicker = false;
      },
      stores_arr: [] as any[]
    });
    const person_time_start = reactive({
      show: false,
      value: currentDate.toDate(),
      fieldValue: "",
      minDate: currentDate.toDate(),
      filter(type: string, options: string[]) {
        if (type === "minute") {
          if (moment(person_time_start.value).get("hour") === 23) {
            return options.filter(option => Number(option) < 59);
          }
        }
        return options;
      },
      confirm(date: Date) {
        person_time_start.fieldValue = moment(date).format("YYYY-MM-DD HH:mm");
        person_time_end.value = null;
        person_time_end.fieldValue = "";
        person_time_start.show = false;
      }
    });
    const person_time_end = reactive({
      show: false,
      value: null,
      fieldValue: "",
      minDate: computed(() => {
        return moment(person_time_start.fieldValue)
          .add(1, "minute")
          .toDate();
      }),
      maxDate: computed(() => {
        if (configInfo.visit_length === "0") {
          return moment(person_time_start.fieldValue)
            .endOf("days")
            .toDate();
        }
        const date = moment(person_time_start.fieldValue).add(configInfo.visit_length, "minute");
        if (date.isAfter(moment(person_time_start.fieldValue).endOf("days"))) {
          return moment(person_time_start.fieldValue)
            .endOf("days")
            .toDate();
        }
        return date.toDate();
      }),
      confirm(date: Date) {
        person_time_end.fieldValue = moment(date).format("YYYY-MM-DD HH:mm");
        person_time_end.show = false;
      }
    });

    const addPerson = () => {
      formData.person_lists.push({
        key: new Date().getTime(),
        name: "",
        mobile: "",
        email: ""
      });
    };
    const deletePerson = (index: number) => {
      formData.person_lists.splice(index, 1);
    };
    const onSubmit = async () => {
      if (configInfo.visit_slas.state === 1 && !formData.checked) {
        Toast.fail("请阅读并同意《服务协议及隐私政策》");
        return;
      }
      const o = {};
      if (formData.visit_diy_field.length > 0) {
        formData.visit_diy_field.forEach(item => {
          o[item.id] = item.value;
        });
      }
      try {
        await visitorService.add({
          person_lists: JSON.stringify(formData.person_lists),
          person_reason: formData.person_reason,
          person_date: moment(person_time_start.fieldValue).format("YYYY-MM-DD"),
          person_time: `${moment(person_time_start.fieldValue).format("HH:mm")}~${moment(
            person_time_end.fieldValue
          ).format("HH:mm")}`,
          visit_stores_id: formData.visit_stores_id,
          visit_stores_name: formData.visit_stores_name,
          visit_user_name: formData.visit_user_name,
          visit_user_mobile: formData.visit_user_mobile,
          visit_user_email: formData.visit_user_email,
          visit_diy_field: JSON.stringify(o)
        });
        router.push({
          name: RouterName.VISITOR_RECORD
        });
      } catch (error) {}
    };

    return {
      moment,
      configInfo,
      formData,
      addPerson,
      deletePerson,
      person_time_start,
      person_time_end,

      onSubmit,
      // pickerConfirm,
      validate
    };
  }
};
